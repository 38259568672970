<template>
  <v-card>
    <v-card-title>
      Colin's Companies
      <v-spacer></v-spacer>
      <v-text-field
        v-model="colinCompanySearch"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      >
      </v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        @update:pagination="updatedPagination"
        :items="colinCompanies"
        :headers="headers"
        :items-per-page.sync="pageSize"
        :server-items-length="colinCompanyCount"
        :page="pageNumber"
        @pagination="updatedPagination"
        :loading="loading"
      >
        <template v-slot:item.zohoId="{ item }">
          <v-btn :href="getZohoLink(item.zohoId)" color="light-blue lighten-4" target="_blank"> Open In Zoho </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import * as courier from "../../../utilities/Courier";

export default {
  watch: {
    colinCompanySearch(newVal, oldVal) {
      this.loading = true;
      clearTimeout(this.debounceId);

      this.debounceId = setTimeout(() => {
        this.getCompanies();
      }, 300);
    },
  },
  mounted() {
    this.getCompanies();
  },
  methods: {
    getZohoLink(zohoId) {
        return 'https://crm.zoho.eu/crm/org20068100338/tab/Accounts/' + zohoId.replace('zcrm_', '');
    },
    getCompanies() {
      this.loading = true;
      this.$courier.ColinCompanies
        .getData(
          this.pageNumber,
          this.pageSize,
          this.colinCompanySearch
        )
        .then((companiesFromColin) => {
          this.colinCompanies = companiesFromColin.payload;
          this.colinCompanyCount = companiesFromColin.totalCount;
          this.loading = false;
        });
    },
    updatedPagination(paginationObject) {
      this.pageSize = paginationObject.itemsPerPage;
      this.pageNumber = paginationObject.page;
      this.getCompanies();
    },
  },
  data: () => ({
    debounceId: "",
    loading: true,
    pageNumber: 1,
    pageSize: 10,
    colinCompanySearch: "",
    colinCompanyCount: 0,
    colinCompanies: [],
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "companyName",
      },
      {
        text: "Date Added",
        align: "start",
        sortable: false,
        value: "dateAdded",
      },
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      {
        text: "Zoho Link",
        align: "start",
        sortable: false,
        value: "zohoId",
      },
    ],
  }),
};
</script>

<style>
</style>